<template>
  <div>
    <div v-if="this.$route.name === 'PeriodClosure'">
      <PeriodClosure />
    </div>
  </div>
</template>

<script>
import PeriodClosure from "@/views/companyApp/periodClosure/PeriodClosure.vue";

export default {
  name: "PeriodClosureParent",
  components: {
    PeriodClosure,
  },
};
</script>